import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard"

import Accordion from "./Accordion";

import "./FAQ.scss";

import instructions from "../../images/faq-instructions.svg"
import overlay from "../../images/faq-overlay.svg"
import inject from "../../images/faq-inject.svg"

import install from "../../images/faq-error-install.svg"
import invisible from "../../images/faq-error-invisible.svg"
import binds from "../../images/faq-error-binds.svg"
import menu from "../../images/faq-error-menu.svg"
import network from "../../images/faq-error-network.svg"
import memory from "../../images/faq-error-memory.svg"
import allocate from "../../images/faq-error-allocate.svg"
import dependencies from "../../images/faq-error-dependencies.svg"
import not_supported from "../../images/faq-error-not_supported.svg"
import render from "../../images/faq-error-render.svg"
import disk from "../../images/faq-error-disk.svg"
import close from "../../images/faq-error-close.svg"
import server from "../../images/faq-error-server.svg"
import instructions_not_supported from "../../images/faq-error-instructions_not_supported.svg"
import copy from "../../images/faq-copy-icon.svg"

import alert_square from "../../images/faq-alert-square.svg"
import alert_triangle from "../../images/faq-alert-triangle.svg"

let external_instructions = [
    {
        image: instructions,
        header: "System Requirements for Running Rust External Loader",
        text: 
        <>
            <span>— Windows 10/11 Home/Pro</span>

            <span>— <b>Virtualization</b> (VT-x/VT/SVM) must be <b>enabled</b></span>

            <span>— <b>TPM</b> (Trusted Platform Module) is recommended to <b>disable</b></span>

            <span>— <b>Hyper-V </b>and <b>Virtual Machine Platform</b> must be <b>disabled</b> in Windows features:</span>
            <ul>
                <li>Go to <b>Control Panel</b> -{">"} <b>Turn Windows features on or off</b> -{">"} <b>Disable</b> "Virtual Machine Platform" and "Hyper-V"</li>
                <li>Open <b>CMD</b> and type bcdedit /set hypervisorlaunchtype off</li>
                <li>After that, <b>restart your PC</b></li>
            </ul>

            <span>
                — <b>Launch options</b> in Steam for Rust should be <b>empty</b>.<br />
                Steam and Rust should <b>NOT</b> be run as administrator.
            </span>

            <span>— All <b>antivirus programs</b> on your PC must be <b>turned off</b>.</span>
        </>
    },
    {
        image: overlay,
        header: "Overlay Installation",
        text: 
        <>
            <p>NVIDIA GPU:</p>
            <ul>
                <li>Download the <a class="faq_questions_link" href="https://www.nvidia.com/en-us/software/nvidia-app/" target="_blank">NVIDIA App</a> or <a class="faq_questions_link" href="https://www.nvidia.com/en-us/geforce/geforce-experience/" target="_blank">GeForce Experience</a></li>
                <li><b>Enable overlay</b></li>
            </ul>

            <p>AMD GPU:</p>
            <ul>
                <li>Download the <a class="faq_questions_link" href="https://steelseries.com/gg/downloads/gg/latest/windows" target="_blank">SteelSeries GG software</a>.</li>
                <li>Enable <b>SteelSeries Sonar</b>.</li>
                <li>Disable <b>SteelSeries Moments</b>.</li>
                <li>Go to <b>Settings</b> -{">"} scroll down to <b>Sonar Shortcuts</b> -{">"} and set up a custom key bind.</li>
                <li>Before injecting, press the key bind to enable the SteelSeries overlay.</li>
            </ul>
        </>
    },
    {
        image: inject,
        header: "How to Inject",
        text: 
        <>
            <span>— Ensure that the <b>overlay</b> is active.</span>
            <span>— Download the <b>loader</b>.</span>
            <span>— Make sure that <b>Rust</b> is closed.</span>
            <span>— Open the loader, either from the <b>local disk</b> or from a <b>USB drive</b>.</span>
            <span>— <b>Right-click</b> to paste your license key.</span>
            <span>— Follow the instructions shown in the <b>loader</b>.</span>
            <span>— Upon the first injection, the PC will <b>restart</b>, after which a second injection is required.</span>
            <span>— Once the <b>message box</b> confirms a successful injection, you may <b>start the game</b>.</span>
            <span>— It is highly recommended to enable <b>VSync in Rust</b>.</span>
        </>
    },
]

let external_instructions_errors = [
    {
        image: invisible,
        header: "Invisible Check is not working",
        text: <span>To get <b>Visible Check</b> to work, you need to enable <b>Occlusion Culling</b> in Rust settings (Options -{">"} Experimental -{">"} <b>Occlusion Culling</b> (ON)).</span>
    },
    {
        image: binds,
        header: "Binds are not working properly",
        text: <span>Delete <code>client.cfg</code> and <code>keys.cfg</code> from the Rust folder: <code>C:\Program Files (x86)\Steam\steamapps\common\Rust\cfg</code>, and then reinject.</span>
    },
    {
        image: menu,
        header: "Menu is not clickable",
        text: 
        <>
            <span>Run your overlay app as <b>administrator</b>:</span>
            <ul>
                <li><b>NVIDIA</b>: <code>C:\Program Files\NVIDIA Corporation\NVIDIA GeForce Experience\NVIDIA Share.exe</code> -{">"} <b>Properties</b> -{">"} <b>Compatibility</b> -{">"} <b>Run this program as an administrator</b> (ON).</li>
                <li><b>AMD</b>: Run <b>SteelSeries GG</b> as administrator.</li>
            </ul>
        </>
    },
    {
        image: network,
        header: "Unknown network error",
        text: <span>There may be an issue with your internet connection. Please ensure it is stable, and consider disabling any VPN programs.</span>
    },
    {
        image: allocate,
        header: "Failed to allocate memory",
        text: <span>The driver was not installed correctly. Please <b>restart your PC</b> and try again.</span>
    },
    {
        image: memory,
        header: "Failed to map memory",
        text: 
        <>
            <span>Disable all autorun programs by following these steps:</span>
            <ul>
                <li>Open <b>Task Manager</b>.</li>
                <li>Go to the <b>Startup</b> tab.</li>
                <li>Disable all programs to prevent them from automatically running at startup.</li>
            </ul>
        </>
    },
    {
        image: dependencies,
        header: "Failed to load dependencies",
        text: <span>Ensure that all <b>antivirus programs</b> are disabled. If the issue persists, try disabling all programs in the <b>startup (autorun)</b> list.</span>
    },
    {
        image: not_supported,
        header: "VMX/SVM is not supported",
        text: 
        <>
            <span><b>Hyper-V</b> and <b>Virtual Machine Platform</b> must be disabled in <b>Windows Features</b>:</span>
            <ul>
                <li>Go to <b>Control Panel</b> -{">"} <b>Turn Windows features on or off</b> {">"} <b>Disable "Virtual Machine Platform" and "Hyper-V</b>."</li>
                <li>Open <b>CMD</b> and type:
                <div className="code_section">
                        <div className="code_copied" data-error-code-copied-id="1">Copied!</div>
                        <CopyToClipboard 
                            text={`bcdedit /set hypervisorlaunchtype off`}
                            onCopy={() => {
                                if(document.querySelector(`div[data-error-code-copied-id="1"]`).classList.contains("copied")) return;
                                document.querySelector(`div[data-error-code-copied-id="1"]`).classList.add("copied");
                                setTimeout(() => {
                                    document.querySelector(`div[data-error-code-copied-id="1"]`).classList.remove("copied");
                                }, 2800)
                            }} 
                        >
                            <div className="code_top_section">
                                <span>shell</span>
                                <img src={copy} alt="" />
                            </div>
                        </CopyToClipboard>
                        <div className="code_section_content">
                            <span className="code_blue">bcdedit /set hypervisorlaunchtype off</span>
                        </div>
                    </div>
                </li>
                <li>Restart your PC afterward.</li>
            </ul>
            <span>If you're still experiencing this error and you are on <b>Windows 11 24H2 or higher</b>:</span>
            <ul>
                <b>Ensure PIN and Password Are Disabled</b>
                <li>Go to <b>Windows Account</b> Settings and disable both the PIN and password.</li>
                <b>Run the Following Commands in CMD</b>
                <li><b>Open Command Prompt (CMD)</b> as administrator and enter these commands:<br />
                    <div className="code_section">
                        <div className="code_copied" data-error-code-copied-id="2">Copied!</div>
                        <CopyToClipboard 
                            text={`takeown /F "C:\\Windows\\System32\\hvix64.exe"\nicacls "C:\\Windows\\System32\\hvix64.exe" /grant $(([System.Security.Principal.WindowsIdentity]::GetCurrent()).User.Value):F\ntakeown /F "C:\\Windows\\System32\\hvax64.exe"\nicacls "C:\\Windows\\System32\\hvax64.exe" /grant$(([System.Security.Principal.WindowsIdentity]::GetCurrent()).User.Value):F\ndel "C:\\Windows\\System32\\hvix64.exe"\ndel "C:\\Windows\\System32\\hvax64.exe"`}
                            onCopy={() => {
                                if(document.querySelector(`div[data-error-code-copied-id="2"]`).classList.contains("copied")) return;
                                document.querySelector(`div[data-error-code-copied-id="2"]`).classList.add("copied");
                                setTimeout(() => {
                                    document.querySelector(`div[data-error-code-copied-id="2"]`).classList.remove("copied");
                                }, 2800)
                            }} 
                        >
                            <div className="code_top_section">
                                <span>powershell</span>
                                <img src={copy} alt="" />
                            </div>
                        </CopyToClipboard>
                        <div className="code_section_content">
                            <span className="code_blue">takeown <span className="code_red">/</span>F <span className="code_yellow">"C:\Windows\System32\hvix64.exe"</span></span><br />
                            <span className="code_blue">icacls <span className="code_yellow">"C:\Windows\System32\hvix64.exe"</span> <span className="code_red">/</span>grant $<span className="code_red">((</span>[System.Security.Principal.WindowsIdentity]::GetCurrent<span className="code_red">()).</span>User<span className="code_red">.</span>Value<span className="code_red">)</span>:F</span><br />
                            <span className="code_blue">takeown <span className="code_red">/</span>F <span className="code_yellow">"C:\Windows\System32\hvax64.exe"</span></span><br />
                            <span className="code_blue">icacls <span className="code_yellow"></span><span className="code_yellow">"C:\Windows\System32\hvax64.exe"</span> <span className="code_red">/</span>grant $<span className="code_red">((</span>[System.Security.Principal.WindowsIdentity]::GetCurrent<span className="code_red">()).</span>User<span className="code_red">.</span>Value<span className="code_red">)</span>:F</span><br />
                            <span className="code_blue">del <span className="code_yellow">"C:\Windows\System32\hvix64.exe"</span></span><br />
                            <span className="code_blue">del <span className="code_yellow">"C:\Windows\System32\hvax64.exe"</span></span>
                        </div>
                    </div>
                </li>
                <li>If it doesnt help or you get errors try to enter these commands:<br />
                    <div className="code_section">
                        <div className="code_copied" data-error-code-copied-id="3">Copied!</div>
                        <CopyToClipboard 
                            text={`takeown /F "C:\\Windows\\System32\\hvix64.exe"\nicacls "C:\\Windows\\System32\\hvix64.exe" /grant Users:F\ntakeown /F "C:\\Windows\\System32\\hvax64.exe"\nicacls "C:\\Windows\\System32\\hvax64.exe" /grant Users:F\ndel "C:\\Windows\\System32\\hvix64.exe"\ndel "C:\\Windows\\System32\\hvax64.exe"`}
                            onCopy={() => {
                                if(document.querySelector(`div[data-error-code-copied-id="3"]`).classList.contains("copied")) return;
                                document.querySelector(`div[data-error-code-copied-id="3"]`).classList.add("copied");
                                setTimeout(() => {
                                    document.querySelector(`div[data-error-code-copied-id="3"]`).classList.remove("copied");
                                }, 2800)
                            }} 
                        >
                            <div className="code_top_section">
                                <span>powershell</span>
                                <img src={copy} alt="" />
                            </div>
                        </CopyToClipboard>
                        <div className="code_section_content">
                            <span className="code_blue">takeown <span className="code_red">/</span>F <span className="code_yellow">"C:\Windows\System32\hvix64.exe"</span></span><br />
                            <span className="code_blue">icacls <span className="code_yellow">"C:\Windows\System32\hvix64.exe"</span> <span className="code_red">/</span>grant Users:F</span><br />
                            <span className="code_blue">takeown <span className="code_red">/</span>F <span className="code_yellow">"C:\Windows\System32\hvax64.exe"</span></span><br />
                            <span className="code_blue">icacls <span className="code_yellow">"C:\Windows\System32\hvax64.exe"</span> <span className="code_red">/</span>grant Users:F</span><br />
                            <span className="code_blue">del <span className="code_yellow">"C:\Windows\System32\hvix64.exe"</span></span><br />
                            <span className="code_blue">del <span className="code_yellow">"C:\Windows\System32\hvax64.exe"</span></span>
                        </div>
                    </div>
                </li>
            </ul>
        </>
        
    },
    {
        image: install,
        header: "Please install d3dx11_43.dll",
        text: <span>Install DirectX library: <a class="faq_questions_link" href="https://www.microsoft.com/en-us/download/details.aspx?id=35" target="_blank">dxwebsetup.exe</a>.</span>
    },
    {
        image: render,
        header: "Failed to initialize render",
        text: <span>Disable <b>SteelSeries Moments</b> and enable <b>SteelSeries Sonar</b>.</span>
    },
    {
        image: disk,
        header: "Either virtual or RAID disk is present",
        text: <span>Turn off <b>RAID</b> in the BIOS and disable <b>Intel Rapid Storage Technology (RST)</b>. If this doesn’t resolve the issue, try <b>reinstalling Windows</b>.</span>
    },
    {
        image: close,
        header: "Loader closing without any error or menu does not appear",
        text: <span>Install the required library: <a class="faq_questions_link" href="https://aka.ms/vs/17/release/vc_redist.x64.exe" target="_blank">vc_redist.x64.exe</a>.</span>
    },
    {
        image: server,
        header: "No available servers were found",
        text: <span>Check the news in our <a class="faq_questions_link" href="https://discord.gg/radianceproject" target="_blank">Discord channel</a> (Servers may be under maintenance).</span>
    },
    {
        image: instructions_not_supported,
        header: "AES instruction set is not supported",
        text: <span>Your CPU does not support <b>AES instructions</b>, or AES is disabled in the <b>BIOS</b>.</span>
    },
]

export default function Instructions() {
    const [openIndex, setOpenIndex] = React.useState(-1);
    const [openIndex2, setOpenIndex2] = React.useState(-1);

    return(
        <div className="instructions_section">
            <div className="inctructions_header" data-id="0">
                <div className="inctructions_title">Rust External</div>
                <p>
                    <img src={alert_square} alt="" />
                    To paste your license key, you should use the right mouse button!
                </p>
            </div>
            <div className="faq_accordion_wrapper" data-id="0">
                {
                    external_instructions.map((e, i, a) =>
                        <>
                            <Accordion 
                                icon={e.image}
                                header={e.header}
                                text={e.text}
                                index={i}
                                clickFn={setOpenIndex}
                                accordion_state={openIndex === i}
                                key={"faq_accordion" + i}
                            />
                            {i + 1 == a.length ? <></> : <div className="faq_separator"></div>}
                        </>
                    )
                }
            </div>
            <div className="inctructions_header" data-id="1">
                <p className="red">
                    <img src={alert_triangle} alt="" />
                    Common issues.
                </p>
            </div>
            <div className="faq_accordion_wrapper" data-id="1">
                {
                    external_instructions_errors.map((e, i, a) =>
                        <>
                            <Accordion 
                                icon={e.image}
                                header={e.header}
                                text={e.text}
                                index={i}
                                clickFn={setOpenIndex2}
                                accordion_state={openIndex2 === i}
                                key={"faq_accordion" + i}
                            />
                            {i + 1 == a.length ? <></> : <div className="faq_separator"></div>}
                        </>
                    )
                }
            </div>
        </div>
    )
}